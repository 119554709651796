/* _________________________ FONTS FAMILIES _____________________ */

@font-face {
    font-family: 'libertad_monoregular';
    src: url('../static/fonts/libertadmono/libertadmono-regular-webfont.woff2') format('woff2'),
        url('../static/fonts/libertadmono/libertadmono-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeueHaasGrotesk-Medium';
    src: url('../static/fonts/neuehaasgrotesk/haasgrotdisp-65medium-webfont.woff') format('woff'),
        url('../static/fonts/neuehaasgrotesk/haasgrotdisp-65medium-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

/* _________________________ BASIC HTML STANDARD _____________________ */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    text-decoration: none;
    /* touch-action: none; */
    text-transform: uppercase;
    color: var(--colorBlack);
    /* pointer-events: none; */
    text-decoration: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    font-size: 16px;
    overflow: hidden;
    touch-action: pan-x pan-y;
    /* height: 100%  */
}


html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: 'libertad_monoregular';
}

:root {
    --colorWhite: #ffffff;
    --colorBlack: #000000;
    --colorGrey: #383838;
    --textSize: 14px;
    --blurredBG: blur(8px);
    --animDelay: 0.3s;

}

/* _________________________NAV _____________________ */
nav {
    justify-content: center;
    align-items: center;
    padding-left: 0.8em;
    padding-right: 0.8em;
    color: var(--colorWhite);
    height: 75px;
    width: 100%;
    border-bottom: var(--colorGrey) 1px solid;
    position: absolute;
    top: 0;
    z-index: 100;
    font-size: 20px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: space-between;
    /* backdrop-filter: var(--blurredBG); */
    /* background-color: rgb(0, 0, 0, 0.7); */
}


.tuto_wrap {
    display: flex;
    justify-content: right;
    align-items: center;
    /* background-color: var(--colorBlack); */
}

.symbol_link {
    width: 50px;
    height: auto;
    display: flex;
    border: 2px solid var(--colorGrey);
    border-radius: 5px;
    transition-duration: var(--animDelay);
}

.symbol_link:hover {
    background-color: var(--colorBlack);
}

.symbol_link img {
    width: 100%;
    height: auto;
}

.logo img {
    width: 100%;
    height: auto;
}


/* _________________________CONTENT _____________________ */


.log_container {
    display: none;
    height: 25%;
    border-top: 1px solid var(--colorBlack);
    padding: 15px;
    /* background-color: green; */
}

.arrow {
    /* margin-top: 40px; */
    width: 35px;
    height: auto;
}

.arrow img {
    width: 100%;
    height: auto;
}


.log_box {
    height: 100%;
    width: 100%;
    border: 1px solid var(--colorGrey);
    background-color: var(--colorWhite);
    padding: 10px;
    color: var(--colorGrey);
}

/* _________________________NEW MAIN CONTENT DIV_____________________ */
/* _________________________*************** _____________________ */
/* _________________________*************** _____________________ */
/* _________________________*************** _____________________ */
.container {
    width: 100%;
    height: 100%;
    background-color: var(--colorBlack);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 6em;
    padding-right: 6em; */
}


.middle_screen {
    background-color: var(--colorWhite);
    height: 53%;
    margin-bottom: 65px;
    /* padding-left: 1.5em;
  padding-right: 1.5em; */
    width: 80%;
    position: relative;
}

.steps {
    /* justify-content: space-between; */
    justify-content: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    /* margin-top: 1.5em;
    margin-bottom: 1.5em; */
    /* box-sizing: border-box; */
}

/* .scan_container,
.start_container,
.actions_container {
} */

.scan_container {
    z-index: 50;
    /* background-color: blue; */
}

.start_container {
    z-index: 30
        /* background-color: yellow; */
}

.actions_container {
    padding-left: 1.2em;
    padding-right: 1.2em;
    justify-content: end;
    padding-bottom: 1.4em;
    /* background-color: green; */
}

.instructions {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    font-size: 0.9em;
    letter-spacing: 0px;
}

.number_step {
    font-family: 'NeueHaasGrotesk-Medium';
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--colorBlack);
    border-radius: 40px;
    margin-bottom: 6px;
    font-size: 1.4em;
}

button {
    font-family: 'NeueHaasGrotesk-Medium';
    font-weight: normal;
    padding: 9px 15px;
    color: var(--colorWhite);
    font-size: 1.65em;
    border-radius: 6px;
    border: 0.1em solid var(--colorGrey);
    background-color: var(--colorBlack);
    position: relative;
    letter-spacing: 1px;
    transition-duration: var(--animDelay);
    cursor: pointer;
    display: flex;
    align-items: center;
    background-image: url('/static/graphics/left_bracket.svg'), url('/static/graphics/right_bracket.svg');
    background-repeat: no-repeat;
    background-position-x: 2%, 98%;
    background-size: auto 97%, auto 97%;
    background-position-y: center;
    /* top:30% */
}

button:hover {
    background-color: var(--colorGrey);
    color: var(--colorWhite);
}


.fade-out {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.choices {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.choices img {
    width: 30px;
    margin-right: 8px;
    height: auto;
}

.logo {
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.logo_wrap {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}

a {
    justify-content: center;
    display: flex;
    align-items: center;
}


/* _________________________PANNEL OVERLAY _____________________ */
#close {
    color: var(--colorWhite);
    padding: 0;
}

.close_button_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
}

#close img {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.video_wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* for an aspect ratio of 16:9, use 75% for 4:3 */
    height: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.video_wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.panel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 100;
    color: var(--colorBlack);
    box-sizing: border-box;
    background-color: var(--colorWhite);
}

/* panel content (only for animation delay after open) */
.panel__content {
    opacity: 0;
    will-change: margin-top;
    transition: all 700ms;
    transition-delay: 600ms;
    /* padding: 10px 20px; */
    margin-top: -5%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* Panel content animation after open */
.panel:target .panel__content {
    opacity: 1;
    margin-top: 0;
}

/*  Specific panel "Slice" */
.panel#slice {
    background-color: var(--colorBlack);
    transition: all 700ms cubic-bezier(0.19, 1, 0.56, 1);
    transform: translate3d(0, -100%, 0);
}

.panel#slice:target {
    transform: translate3d(0, 0, 0);
}